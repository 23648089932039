import merge from "deepmerge"
import defaultThemeColors from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors"

// const darkBlue = `#007acc`
// const lightBlue = `#66E0FF`
const blueGray = `#282c35`

const ruby = `#982649`
const muteBlue = `#71A2B6`
// const silver = `#7C8483`
// const vividBlue = `#60B2E5`
// const electricBlue = `#53F4FF`

export default merge(defaultThemeColors, {
  primary: ruby,
  text: blueGray,
  heading: blueGray,
  highlight: muteBlue,
  modes: {
    dark: {
      background: blueGray,
      primary: ruby,
      highlight: ruby,
    },
  },
})
